.App {
  text-align: center;
  position: relative;
}

.group {
  // width: 95%;
  margin: auto 5px;
  padding-top: 5em;
  padding-bottom: 8em;
  min-height: 100vh;
}

.stars {
  display: grid;
  grid-template-columns: repeat(3, 5em);
  justify-content: center;
  justify-items: center;
}

.star {
  width: 4em;
}

.footer {
  color: #FFF;
  text-align: center;
  width: 100%;
  background: #FF0202;
  padding: 2em 0;

  @media only screen and (min-width: 500px) {
    position: absolute;
    bottom: 0;
  }

  a {
    color: #FFF;
    font-weight: bold;
  }

  p {
    margin: 0 2em;
  }
}
