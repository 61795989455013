@import url('https://fonts.googleapis.com/css?family=Shrikhand&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #17187D;
}

h1 {
  font-family: 'Shrikhand', cursive;
  text-shadow: 6px 4px 0px #FF0202;
  color: #FFFFFF;
  font-size: 4em;
  line-height: 1.2em;
  margin-bottom: 0.2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
}
