$light: #657786;
$black: #000;

.tweet {
  background-color: #FFFFFF;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 39px minmax(0, 1fr) min-content;
  max-width: 385px;
  margin: auto;
  padding: 13px;

  @media only screen and (min-width: 767px) {
    margin: auto;
    max-width: 430px;
  }

  &__dp {
    border-radius: 100%;
    width: 100%;
  }

  &__header {
    color: $light;
    display: grid;
    grid-column-gap: 0.3em;
    grid-template-columns: min-content 1fr min-content;
    text-align: left;

    @media only screen and (min-width: 440px) {
      grid-template-columns: repeat(3, min-content);
    }

    p {
      margin: 0;
      white-space: nowrap;

    }
  }

  &__name {
    color: $black;
    font-weight: bold;
    // display: flex;
    // flex-wrap: nowrap;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 2px;
  }

  &__handle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__body {
    margin: 0.3em 0 0.7em;
    text-align: left;
  }

  &__body-handle {
    color: #1b95e0;
  }

  &__stats {
    color: $light;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    // grid-template-columns: ;
  }

  &__stat {
    align-self: end;
    // display: grid;
    font-size: 13px;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    flex-wrap: nowrap;
    justify-self: left;

    img {
      margin-right: 0.5em;
    }

    &:nth-child(4n) {
      justify-self: center;
    }
  }

  &__icons {
    grid-template-rows: auto;
    display: grid;
    font-size: 13px;
  }
}


.disclaimer {
  color: white;
  padding: 1em;
}
