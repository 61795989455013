@import url(https://fonts.googleapis.com/css?family=Shrikhand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #17187D;
}

h1 {
  font-family: 'Shrikhand', cursive;
  text-shadow: 6px 4px 0px #FF0202;
  color: #FFFFFF;
  font-size: 4em;
  line-height: 1.2em;
  margin-bottom: 0.2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
}

.App{text-align:center;position:relative}.group{margin:auto 5px;padding-top:5em;padding-bottom:8em;min-height:100vh}.stars{display:grid;grid-template-columns:repeat(3, 5em);justify-content:center;justify-items:center}.star{width:4em}.footer{color:#FFF;text-align:center;width:100%;background:#FF0202;padding:2em 0}@media only screen and (min-width: 500px){.footer{position:absolute;bottom:0}}.footer a{color:#FFF;font-weight:bold}.footer p{margin:0 2em}

.input{padding-top:2em}input{width:50%;padding:0.5em;border-radius:0.5em;-webkit-appearance:none;outline:none;border:none;font-size:1.2em;max-width:20em}input:focus{border:#63c3d3 solid 2px}@media only screen and (max-width: 600px){input{width:80%}}.button{background:#FFFFFF;position:relative;display:block;margin:2em auto;width:100px;height:50px;font-family:'Shrikhand', cursive;font-size:1.2em;color:#17187D;outline:none;border:2px solid #17187D;transition:all 0.3s}.button::after{content:'';background:#ff0202;background:linear-gradient(45deg, #ff0202 67%, #a60505 67%);display:block;height:100%;width:100%;transform:scale(1.1, 1.2);transform-origin:0% 0%;position:absolute;z-index:-1;top:0;left:0}.button--active{top:10px;left:10px}.button--active::after{transform:scale(1.05, 1.05)}.instructions{color:#FFF}

.tweet{background-color:#FFFFFF;display:grid;grid-column-gap:10px;grid-template-columns:39px minmax(0, 1fr) -webkit-min-content;grid-template-columns:39px minmax(0, 1fr) min-content;max-width:385px;margin:auto;padding:13px}@media only screen and (min-width: 767px){.tweet{margin:auto;max-width:430px}}.tweet__dp{border-radius:100%;width:100%}.tweet__header{color:#657786;display:grid;grid-column-gap:0.3em;grid-template-columns:-webkit-min-content 1fr -webkit-min-content;grid-template-columns:min-content 1fr min-content;text-align:left}@media only screen and (min-width: 440px){.tweet__header{grid-template-columns:repeat(3, -webkit-min-content);grid-template-columns:repeat(3, min-content)}}.tweet__header p{margin:0;white-space:nowrap}.tweet__name{color:#000;font-weight:bold;display:grid;grid-template-columns:repeat(2, auto);grid-column-gap:2px}.tweet__handle{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.tweet__body{margin:0.3em 0 0.7em;text-align:left}.tweet__body-handle{color:#1b95e0}.tweet__stats{color:#657786;display:grid;grid-column-gap:20px;grid-template-columns:repeat(4, 1fr)}.tweet__stat{align-self:end;font-size:13px;display:flex;flex-wrap:nowrap;justify-self:left}.tweet__stat img{margin-right:0.5em}.tweet__stat:nth-child(4n){justify-self:center}.tweet__icons{grid-template-rows:auto;display:grid;font-size:13px}.disclaimer{color:white;padding:1em}

