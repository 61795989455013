.input {
  padding-top:  2em;
}

input {
  width: 50%;
  padding: 0.5em;
  border-radius: 0.5em;
  -webkit-appearance: none;
  outline: none;
  border: none;
  font-size: 1.2em;
  max-width: 20em;

  &:focus {
    border: #63c3d3 solid 2px;
  }

  @media only screen and (max-width: 600px) {
    width: 80%;
  }

}

.button {
  background: #FFFFFF;
  position: relative;
  display: block;
  margin: 2em auto;
  width: 100px;
  height: 50px;
  font-family: 'Shrikhand', cursive;
  font-size: 1.2em;
  color: #17187D;
  outline: none;
  border: 2px solid #17187D;
  transition: all 0.3s;


  &::after {
    content: '';
    background: rgb(255,2,2);
    background: linear-gradient(45deg, rgba(255,2,2,1) 67%, rgba(166,5,5,1) 67%);
    display: block;
    height: 100%;
    width: 100%;
    transform: scale(1.1, 1.2);
    transform-origin: 0% 0%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }

  &--active {
    top: 10px;
    left: 10px;

    &::after {
      transform: scale(1.05, 1.05);
    }
  }

}

.instructions {
  color: #FFF;
}
